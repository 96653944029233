<template>
    <div class="page" >
        <div class=" d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center"> 
        </div>
        <div class="pageBody" >
            <div id="BodyFirstHalf" class="BodyFirstHalf">
                <div>
                    <img width="100px"  style="padding-top: 0px;" :src="thankYouImage" alt="">
                </div>
                <p style="color: #4F4F4F; font-size: x-large; font-weight: 700;" >Your Consultation has been Completed!</p>
                <p style="font-weight: 600; color: #828282;" >
                    Thank you for consulting with 
                    <span style="color: #1467BF !important;" >MyMedicine.</span>
                    You can continue booking consultation anytime.
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'videoCallEndPage',
    data() {
        return {
            thankYouImage: '',
        }
    },
    created() {
        this.thankYouImage = 'https://s3iconimages.mymedicine.com.mm/humbleSmileyGIF.gif';
    },

}
</script>
<style scoped>
.page {
    min-height: 680px;
    /* width: 750px; */
    border-radius: 16px;
    padding: 32px;
    background-color: white;

    display: flex;
    flex-direction: column;

    @media (max-width: 600px) {
        width: 100%;
	}

	@media (min-width: 601px) and (max-width: 900px) {
		width: 600px;
	}

	@media (min-width: 901px) {
		width: 750px;
	}
}
.pageHeading {
    display: flex;
    justify-content: space-between;
}
.pageBody {
    /* margin-top: 75px; */
    /* height: 100% !important; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    align-content: center;
}
.subHeading {
    font-size: large;
    font-weight: 600;
}
.BodyFirstHalf {
    height: 100%;
    width: 60%;
    margin-top: 120px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}
.BodyLastHalf {
    display: flex;
    justify-content: center;
}
</style>